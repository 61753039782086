<template>
    <div class="page-table mainDiv scrollable only-y" id="affix-container">
        <div class="card-base baslikBg animated fadeInUp">
            Belgeler

            <div class="tanimlamaButon">
                <el-tooltip :content='$t("src.views.apps.genel.refresh")' :open-delay="500" placement="top">
                    <el-button v-on:click="refreshPage()" size="mini" type="text" icon="el-icon-refresh"></el-button>
                </el-tooltip>
            </div>
        </div>
        
        <el-row :gutter="10">
            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                <div class="page-header card-base card-shadow--medium animated fadeInUp pt-20" v-loading="loading || stateLoading"
                :element-loading-text='loading ? "Belgeler Yükleniyor. Lütfen Bekleyiniz" : "Belge Durum Güncelleniyor. Lütfen Bekleyiniz."'
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(255, 255, 255, 1)">
                <el-row class="mt-0 mb-10">

                    <!--
                        <el-col :lg="16" :md="16" :sm="24" :xs="24">
                        <div>
                            <el-radio-group size="small" v-on:change="handleChange($event)" v-model="radio" fill="#E5F5F9" text-color="gray">
                                <el-radio-button label="2">
                                    <i class="mdi mdi-page-next mdi-18px" style="color: #f7ba2a; margin-right: 5px"></i>
                                    <transition name="slide-fade">
                                        <label v-if="selectIcon == '2'">Tüm Belgeler</label>
                                    </transition>
                                </el-radio-button>

                                <el-radio-button label="1">
                                    <i class="mdi mdi-page-next mdi-18px" style="color: #13ce66; margin-right: 5px"></i>
                                    <transition name="slide-fade">
                                        <label v-if="selectIcon == '1'">Gelen Belgeler</label>
                                    </transition>
                                </el-radio-button>

                                <el-radio-button label="0">
                                    <i class="mdi mdi-page-next mdi-18px" style="color: #ec205f; margin-right: 5px"></i>
                                    <transition name="slide-fade">
                                        <label v-if="selectIcon == '0'">Giden Belgeler</label>
                                    </transition>
                                </el-radio-button>
                            </el-radio-group>
                        </div>
                    </el-col>
                    -->
                    <el-col :lg="24" :md="24" :sm="24" :xs="24" style="text-align: right;">
                        <el-col :lg="24" :md="24" :sm="24" :xs="24">
                            <ul class="islemler">
                                <li class="duzenle">
                                    <i class="mdi mdi-pen"></i> {{$t("src.views.apps.rakamlar.islem.duzenle")}}
                                </li>
                                <li v-if="selectIcon == '1'" class="sil">
                                    <i class="mdi mdi-trash-can"></i> {{$t("src.views.apps.rakamlar.islem.sil")}}
                                </li>
                                <li v-else class="aktif">
                                    <i class="mdi mdi-play"></i> {{$t("src.views.apps.rakamlar.islem.yayinaAl")}}
                                </li>
                            </ul>
                        </el-col>
                    </el-col>
                </el-row>

                <el-table v-loading="searchLoading"
                          :element-loading-text='"Belge Aranıyor. Lütfen Bekleyin."'
                          element-loading-spinner="el-icon-loading"
                          element-loading-background="rgba(255, 255, 255, 1)"
                          border 
                          stripe 
                          :data="dosyaListe" style="width: 100%">
                    <el-table-column>
                        <el-table-column
                            :label='$t("src.views.apps.genel.sira")'
                            type="index"
                            :index="indexMethod"
                            width="80">
                        </el-table-column>
                    </el-table-column>
                    
                   <el-table-column>                    
                    <el-table-column  
                        label='Belge ID'
                        prop="dosyaID"
                        width="80">
                    </el-table-column>
                   </el-table-column>

                    <!--
                    <el-table-column :label='$t("src.views.apps.urun.liste.table.image")'  width="100" align="center">
                        <template slot-scope="scope">
                            <el-popover transition="el-fade-in-linear" placement="right" width="auto" trigger="hover">
                                <img style="max-height: 600px" v-if="scope.row.dosyaAdi" :src="imagepath+scope.row.dosyaAdi" :alt="scope.row.baslik">
                                <span v-else>Resim Yok.</span>
                                <img v-if="scope.row.dosyaAdi" style="max-height: 50px !important" slot="reference" :src="imagepath+scope.row.dosyaAdi" :alt="scope.row.baslik">
                                <i v-else style="font-size: 24px; cursor: pointer; color: #337ab7" slot="reference" class="el-icon-picture"></i>
                            </el-popover>
                        </template>
                    </el-table-column>
                    -->


                    <el-table-column>
                        <template slot="header" slot-scope="props">
                            <el-input clearable v-on:clear="belgeArama" :placeholder='"Kayıt Kodu Ara"' v-model="kayitKoduAra" v-debounce:500ms="belgeArama" size="mini"></el-input>
                        </template>
                        <el-table-column  
                            label='Belge Kayıt Kodu'
                            prop="kayitKodu"
                            width="auto">
                            <template slot-scope="prop">
                                <div class="new-line-text">
                                    {{prop.row.kayitKodu}}
                                </div>
                            </template>
                        </el-table-column>
                    </el-table-column>

                    <el-table-column>
                        <template slot="header" slot-scope="props">
                            <el-date-picker @change ="belgeArama" :placeholder='"Tarih Ara"' v-model="tarihAra" format="dd.MM.yyyy" value-format="yyyy-MM-dd" size="mini" class="select-wide" type="date"></el-date-picker>
                        </template>  
                        <el-table-column  
                            label='Belge Tarihi'
                            prop="islemTarihi"
                            width="auto"
                            >
                            <template slot-scope="prop">
                                <div class="new-line-text">
                                    {{
                                        new Date(prop.row.islemTarihi)
                                            .toLocaleDateString('tr-TR', {
                                                day: 'numeric',
                                                month: 'numeric',
                                                year: 'numeric'
                                            })
                                    }}
                                </div>
                            </template>
                        </el-table-column>
                    </el-table-column>

                    <el-table-column>
                        <template slot="header" slot-scope="props">
                            <el-input clearable v-on:clear="belgeArama" :placeholder='"Belge Durumu Ara"' v-model="dosyaDurumuAra" v-debounce:500ms="belgeArama" size="mini"></el-input>
                        </template>
                        <el-table-column  
                            label='Belge Durumu'
                            prop="dosyaDurumu"
                            width="200">

                            <template slot-scope="scope">
                                <span v-if="scope.row.dosyaDurumu === 'I'">Gelen</span>
                                <span v-else-if="scope.row.dosyaDurumu === 'O'">Giden</span>
                            </template>

                        </el-table-column>
                    </el-table-column>

                    <el-table-column>
                        <template slot="header" slot-scope="props">
                            <el-input clearable v-on:clear="belgeArama" :placeholder='"Kurum Ara"' v-model="kurumAra" v-debounce:500ms="belgeArama" size="mini"></el-input>
                        </template>
                        <el-table-column  
                            label='Kurum Adı'
                            prop="kurumAdi"
                            width="200">
                        </el-table-column>
                    </el-table-column>
                    
                    <el-table-column>
                        <el-table-column :label='$t("src.views.apps.genel.islem")' align="right" width="150">
                            <template slot-scope="scope">
                                <el-tooltip content='Belgeyi İndir' :open-delay="500" placement="bottom">
                                    <el-button
                                        v-on:click="downloadFile(scope.row); "
                                        type="text"
                                        style="margin-left: 15px !important;"
                                        icon="mdi mdi-download mdi-24px">
                                    </el-button>
                                </el-tooltip>
                                <el-tooltip :content='$t("src.views.apps.rakamlar.islem.duzenle")' :open-delay="500" placement="bottom">
                                    <el-button
                                        v-on:click="updateStart(scope.row); "
                                        type="text"
                                        style="color: #f7ba2a; margin-left: 15px !important;"
                                        icon="mdi mdi-pen mdi-24px">
                                    </el-button>
                                </el-tooltip>
                                <el-tooltip :content='$t("src.views.apps.rakamlar.islem.sil")' :open-delay="500" placement="bottom">
                                    <el-button
                                        v-if="selectIcon == '1'"
                                        v-on:click="dosyaSil(scope.row.dosyaID)"
                                        type="text"
                                        class="buttonDel"
                                        style="margin-left: 15px !important;"
                                        icon="mdi mdi-trash-can mdi-24px">
                                    </el-button>
                                </el-tooltip>
                               
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>

                <el-pagination
                    background
                    layout="sizes,prev, pager, next, jumper, total"
                    :total="total"
                    @size-change="handleSizeChange"
                    @current-change="pageChange"
                    :page-sizes="[10, 20, 50, 100]"
                    :current-page.sync="page"
                    :next-click.sync="page"
                    :prev-click.sync="page"
                    @next-click="pageChange"
                    @prev-click="pageChange">
                </el-pagination>
            </div>
            </el-col>

        <el-col :lg="6" :md="6" :sm="24" :xs="24">
            <div class="card-base card-shadow--medium demo-box bg-white animated fadeInUp" v-loading="addLoading || updateLoading || kurumLoading"
            :element-loading-text='addLoading ? "Belge Tanımlanıyor. Lütfen Bekleyiniz." : updateLoading ? "Belge Güncelleniyor. Lütfen Bekleyiniz.": "Kurum Listesi Yükleniyor. Lütfen Bekleyiniz."'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
                <el-form status-icon :model="belgeForm" :rules="rulesBelge" ref="belgeForm" class="demo-ruleForm">
                    <el-row>

                        <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p" v-if="!guncelle">
                            <el-form-item label="Belge Durumu" prop="belgeDurumu">:
                                <el-select style="width: 100%" size="small"  v-model="belgeForm.belgeDurumID" placeholder="Belge Durumu Seçiniz" @change="getKurumList">
                                    <el-option
                                        v-for="item in belgeForm.belgeDurumu" 
                                        :key="item.belgeDurumID"
                                        :label="item.belgeDurumAdi"
                                        :value="item.belgeDurumID">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>   

                        <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                            <el-form-item label="Kurum" prop="kurumID">:
                                <el-select style="width: 100%" size="small"  v-model="belgeForm.kurumID" placeholder="Kurum Seçiniz">
                                    <el-option
                                        v-for="item in kurumListe" 
                                        :key="item.kurumID"
                                        :label="item.kurumAdi"
                                        :value="item.kurumID">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>         

                        <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p" v-if="!guncelle">
                            <el-form-item label="Tarih" prop="islemTarihi">:
                                <el-tooltip :disabled="true" content='Sözleşme Bitiş Tarihi' :open-delay="250" placement="bottom">
                                    <el-date-picker 
                                        v-model="belgeForm.islemTarihi"
                                        size="small" class="select-wide" 
                                        type="date"
                                        :placeholder='"Tarih Seçiniz"'
                                        format="dd-MM-yyyy"
                                        value-format="yyyy-MM-dd"
                                        >
                                    </el-date-picker>
                                </el-tooltip>
                            </el-form-item>
                        </el-col>      
                        

                        <!---
                        <el-col v-if="btnVisible" :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                            <el-form-item :label='"Resim"' prop="resim">:
                                <el-upload
                                    id="belgeekle-resim"
                                    action=""
                                    :auto-upload="false"
                                    :limit="1"
                                    :file-list="imageListMain"
                                    :on-change="imageUpload"
                                    :on-remove="imageRemove"
                                    accept=".jpg,.jpeg,.png,"
                                    list-type="picture-card">
                                    <i slot="default" class="el-icon-plus"></i>
                                </el-upload>
                            </el-form-item>
                        </el-col>-->

                        <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                            <el-form-item prop="belge">
                                <el-upload 
                                    id="belgeEkle"
                                    action=""
                                    :auto-upload="false"
                                    :limit="1"
                                    :file-list="belgeListMain"
                                    :on-change="belgeUpload"
                                    :on-remove="belgeRemove"
                                    list-type="belge-card"
                                    accept="">
                                <el-button size="mini" type="primary" icon="el-icon-upload">Dosya Seç</el-button>
                                <div class="el-upload__tip" slot="tip" style="font-weight: normal !important">jpg/png/doc/xls/pdf formatında belge yükleyebilirsiniz.</div>
                                </el-upload>
                            </el-form-item>
                        </el-col>                     

                        <el-col :span="24" class="col-p">
                            <el-form-item style="text-align: right;">
                                <el-button v-if="btnVisible" size="small" v-on:click="belgeTanimla('belgeForm')" type="primary">
                                    {{$t('src.views.apps.rakamlar.islem.ekle')}}
                                </el-button>
                                <el-button v-else size="small" v-on:click="belgeGuncelle('belgeForm')" type="primary">
                                    {{$t('src.views.apps.rakamlar.islem.guncelle')}}
                                </el-button>
                                <el-button  size="small" v-on:click="vazgec('belgeForm')">
                                    {{$t('src.views.apps.rakamlar.islem.vazgec')}}
                                </el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-col>
    </el-row>
        
</div>
</template>

<script>
    import dosyaService from '../../../WSProvider/DosyaService'
    import notification from '../../../notification'
    import functions from '../../../functions'
    import EventBus from '@/components/event-bus'
    import JQuery from 'jquery';


    let $ = JQuery;

    export default {
        name: "BelgeListe",
        mounted() {
            this.getDosyaList();
            //this.getKurumList();
            this.getBelgeDurumu();
        },
        data() {
            return {
                guncelle: false,
                loading: false,
                updateLoading: false,
                stateLoading: false,
                addLoading: false,
                searchLoading: false,
                kurumLoading: false,
                selectIcon: '1',
                radio: '1',
                dosyaListe: [],
                kurumListe: [],
                btnVisible: true,
                selection: '',

                imagepathKucuk: dosyaService.imagePathKucuk,
                path: dosyaService.path,
                imagepath: dosyaService.imagePath,

                sizes: 10,
                page: 0,
                postPage: 0,
                total: null,

                belgeListMain: [],
                belgeForm: {
                    dosyaID: "",
                    dosyaAdi: "",
                    kurumAdi: "",
                    kurumID: "",
                    dosyaDurumu: "",
                    islemTarihi:"",
                    kayitKodu: "",
                    belge: [],
                    durum: "1",
                    belgeDurumID:"",
                    belgeDurumu: [
                        {belgeDurumAdi: "Gelen", belgeDurumID: "I"},
                        {belgeDurumAdi: "Giden", belgeDurumID: "O"}
                    ],
                },

                kayitKoduAra: "",
                tarihAra: "",
                dosyaDurumuAra: "",
                kurumAra: "",

                rulesBelge: {
                    //belge: [{required: true, message: "Lütfen Belge Yükleyiniz!", trigger: ['blur', 'input']}]
                },
            }
        },
        methods: {
            refreshPage(){
                this.page = 1;
                this.postPage = 0;
                this.getDosyaList();
                this.getKurumList();
            },

            
            downloadFile(item){
                console.log(item)
                var downloadLink = document.createElement("a");
                downloadLink.href = dosyaService.dosyaPath + item.dosyaAdi;
                downloadLink.download = item.dosyaAdi;
                downloadLink.target = "_blank"; 
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            },

            getBelgeDurumu() {
                this.belgeForm.belgeDurumu = this.belgeForm.belgeDurumu;
            },
           

            getDosyaList() {
                try{
                    this.loading = true;
                    dosyaService.dosyaListele(this.selectIcon, this.postPage, this.sizes).then((response) => {
                        if (response.status == 200) {
                            localStorage.setItem("userDataDemirag", response.token);
                            response.data.forEach(el => {
                                el = functions.removeSlashesAndDecodeStrings(el)
                            })
                            this.dosyaListe = response.data;
                        }
                        this.loading = false;
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.dosyaListe = [];
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }
                        this.loading = false;
                    })
                }catch(e){
                    this.loading = false;
                }
            },

            getKurumList() {
                try{
                    this.kurumLoading = true;
                    dosyaService.kurumListele(this.belgeForm.belgeDurumID, this.postPage, this.sizes).then((response) => {
                        if (response.status == 200) {
                            localStorage.setItem("userDataDemirag", response.token);
                            response.data.forEach(el => {
                                el = functions.removeSlashesAndDecodeStrings(el)
                            })
                            this.kurumListe = response.data;
                        }
                        this.kurumLoading = false;
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.kurumListe = [];
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }
                        this.kurumLoading = false;
                    })
                }catch(e){
                    this.kurumLoading = false;
                }
            },

            // kurumAdi, kayitKodu, dosyaDurumu, islemTarihi, baslangic, limit
            belgeArama(){
                this.postPage = 0;
                this.page = 1
                this.dateVisible = false;

                if(this.kurumAra.length > 0 || this.kayitKoduAra.length > 0 || this.dosyaDurumuAra.length > 0 || this.tarihAra){
                    try{
                        this.searchLoading = true;
                        //kullaniciAdi, adSoyad, mail, telefon, durum, baslangic, limit)
                        dosyaService.dosyaArama(this.kurumAra, this.kayitKoduAra, this.dosyaDurumuAra, this.tarihAra, this.postPage, this.sizes).then(response => {
                            if(response.status == 200){
                                this.dosyaListe = response.data;
                                this.total = response.count
                            }
                            localStorage.setItem("userDataDemirag", response.token);
                            this.searchLoading = false;
                        }).catch(err => {
                            if(err.responseJSON){
                                let error = err.responseJSON
                                if(error.status == 401){
                                    notification.Status(150, this);
                                }else if(error.status == 404){
                                    this.dosyaListe = []
                                }
                            }else{
                                //notification.Status(503, this)
                            }
                            this.searchLoading = false;
                        })
                    }catch(e){
                        // notification.Status("danger", this, "An error occurred during the customer search process.")
                        this.searchLoading = false
                    }
                }else{
                    this.getDosyaList();
                    // this.getSozlesmeSayisi();
                }
            },

            belgeRemove() {
                $('#belgeEkle .el-upload--belge-card').show();
                this.belgeListMain = [];
                this.belgeForm.belge = [];
            },

            belgeUpload(file, fileList) {
                this.belgeListMain = [];
                this.belgeForm.belge = [];

                    var item = {
                        name: file.name,
                        url: file.url,
                        id: file.raw.lastModified,
                        size: file.raw.size
                    };
                    this.belgeListMain.push(item);

                    file.raw["tmp_name"] = file.url;

                    this.belgeForm.belge.push(file.raw);

                    fileList.pop();

                    //$('#belgeekle-resim .el-upload--picture-card').hide();
                
            },

            vazgec(formName) {
                this.$refs[formName].resetFields();
                this.btnVisible = true;
                this.belgeForm.belgeDurumID = "";
                this.belgeListMain = [];
                this.belgeForm.belge = []
                $('#belgeEkle .el-upload--belge-card').show();
                this.guncelle = false;
            },

            updateStart(scope) {
                console.log(scope)
                this.guncelle = true;

                if(scope.dosyaAdi != ''){
                    this.belgeListMain.push({
                        name: scope.dosyaAdi,
                        url: dosyaService.dosyaPath + scope.dosyaAdi
                    });
                }
                
                
                this.belgeForm.belge.push(scope.dosyaAdi);

                this.belgeForm.dosyaID = scope.dosyaID;
                this.belgeForm.dosyaAdi = scope.dosyaAdi;
                this.belgeForm.belgeDurumu = scope.dosyaDurumu == "I" ? "Gelen" : "Giden";   
                
                
                this.belgeForm.kurumID = scope.kurumID; 
                this.belgeForm.kurumAdi = scope.kurumAdi;  
                this.belgeForm.belgeDurumID = scope.dosyaDurumu;
                this.getKurumList();
                
                this.belgeForm.islemTarihi = new Date(scope.islemTarihi);
               // this.belgeForm.islemTarihi.valueFormat = "dd.MM.yyyy"
                $('#belgeEkle .el-upload--belge-card').hide();
               // this.btnVisible = false;
                document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
            },

            belgeGuncelle(formName) {
                if(this.validateForm(formName)){
                    this.openConfirmDialog("Belgeyi güncellemek istediğinize emin misiniz?").then(() => {
                        try{
                            this.updateLoading = true;
                                dosyaService.belgeGuncelle(this.belgeForm.dosyaID, this.belgeForm.dosyaAdi, this.belgeForm.durum).then((response) => {
                                if (response.status == 200) {
                                    notification.Status('success', this, response.msg);
                                    this.getDosyaList();
                                    this.getKurumList();
                                    this.vazgec(formName)
                                }
                                this.updateLoading = false;
                            }).catch(err => {
                                if(err.responseJSON){
                                    let error = err.responseJSON
                                    if(error.status == 401){
                                        EventBus.$emit("userLogout", true)
                                    }
                                    notification.Status("warning", this, error.errMsg)
                                }else{
                                    //notification.Status(503, this)
                                }
                                this.updateLoading = false;
                            })
                        }catch(e){
                            this.updateLoading = false
                        }
                    })
                }
            },

            belgeTanimla(formName) {
                if(this.validateForm(formName)){
                    this.openConfirmDialog("Belgeyi eklemek istediğinize emin misiniz?").then(() => {
                        try{
                            this.addLoading = true;
                            dosyaService.dosyaEkle(this.belgeForm.kurumID, this.belgeForm.belgeDurumID, this.belgeForm.islemTarihi, this.belgeForm.belge[0]).then(response => {
                                if(response.status == 200){
                                    localStorage.setItem("userDataDemirag", response.token)
                                    this.getDosyaList();
                                    this.vazgec(formName);
                                }
                                this.addLoading = false;
                                notification.Status("success", this, response.msg)
                            }).catch(err => {
                                if(err.responseJSON){
                                    let error = err.responseJSON
                                    console.log(error)
                                    if(error.status == 401){
                                        notification.Status(150, this);
                                    }
                                    else {
                                        if(error.status == 400){
                                            notification.Status("warning", this, error.errMsg)
                                        }
                                        if(error.status == 503){
                                            notification.Status("warning", this, error.msg)
                                        }
                                    }
                                }else{
                                    //notification.Status(503, this)
                                }
                                this.addLoading = false;
                            })
                        }catch(e){
                            this.addLoading = false;
                        }
                    })
                }
            },

            dosyaSil(dosyaID) {
                var msg =  "Belgeyi silmek istediğinize emin misiniz?" 
                this.openConfirmDialog(msg).then(() => {
                    try{
                        this.stateLoading = true;
                        dosyaService.dosyaSil(dosyaID).then(response => {
                            if (response.status == 200) {
                                localStorage.setItem("userDataDemirag", response.token)
                                this.getDosyaList();
                                this.stateLoading = false;
                            }
                            notification.Status("success", this, response.msg)
                        }).catch(err => {
                            if(err.responseJSON){
                                let error = err.responseJSON
                                if(error.status == 401){
                                    notification.Status(150, this);
                                }
                                else notification.Status("warning", this, error.msg)
                            }else{
                                //notification.Status(503, this)
                            }
                            this.stateLoading = false;
                        })
                    }catch(e){
                        this.stateLoading = false;
                    }
                })                
            },

           handleSizeChange(val) {
                this.sizes = val;
                this.getDosyaList();
                this.getKurumList();
            },

            
            handleChange(event) {
                this.selectIcon = event;
                this.postPage = 0;
                this.page = 1;
                this.getDosyaList();
                this.getKurumList();
                document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
            },

            pageChange(val) {
                this.postPage = (val - 1) * this.sizes;
                this.getDosyaList();
                this.getKurumList();
                document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
            },

            indexMethod(index){
                return ((this.page - 1)* 10) + index + 1;
            },
        }
    }
</script>

<style lang="scss">
@import '../../../assets/scss/_variables';
    .el-pagination {
        margin-top: 20px;
        float: right;
    }

    // el-uploada ait animasyonu kaldırmak için eklendi
    #belgeekle-resim{
        .el-upload-list__item {
            transition: none !important;
        }
    }
</style>

<style>
    .el-upload-list__item-name [class^="el-icon"] {
        font-size: 20px;
        height: 100%;
        margin-right: 7px;
        color: #909399;
        line-height: inherit;
    }
</style>